<template>
    <div v-if="challenge !== null">
      Modify Challenge
      <hr />
      <h3>{{ challenge.away.name }} @ {{ challenge.home.name }} </h3>
      <hr />
      <b-form-group label="Game Time: (2019-03-19)">
        <b-form-input v-model="challenge.eventDate" default="2024-10-" />
      </b-form-group>
      <b-form-group label="Game Time: (12:00:00)">
        <b-form-input v-model="challenge.eventTime" />
      </b-form-group>
  
      <hr />
  
      <div>
        <h3>Vegas Odds</h3>
        <b-form-group label="Line:">
          <b-form-select v-model="line" :options="lineOptions" size="sm"></b-form-select>
        </b-form-group>
        <b-form-group label="Money Line Winner">
          <b-form-input v-model="challenge.lineFOdds" size="sm"></b-form-input>
        </b-form-group>
        <b-form-group label="Money Line Loser">
          <b-form-input v-model="challenge.lineAOdds" size="sm"></b-form-input>
        </b-form-group>
        <b-form-group label="Spread:">
          <b-form-input v-model="challenge.spread" size="sm"></b-form-input>
        </b-form-group>
        <b-form-group label="Over">
          <b-form-input v-model="challenge.spreadFOdds" size="sm"></b-form-input>
        </b-form-group>
        <b-form-group label="Under">
          <b-form-input v-model="challenge.spreadAOdds" size="sm"></b-form-input>
        </b-form-group>
        <b-form-group label="Points:">
          <b-form-input v-model="challenge.totalpoints" size="sm"></b-form-input>
        </b-form-group>
        <b-form-group label="Over">
          <b-form-input v-model="challenge.totalFOdds" size="sm"></b-form-input>
        </b-form-group>
        <b-form-group label="Under">
          <b-form-input v-model="challenge.totalAOdds" size="sm"></b-form-input>
        </b-form-group>
        <b-button variant="danger" style="margin-top:10px" class="btn-block" @click="create">Update</b-button>
        <p>{{message}}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { api } from "@/utils/api";
  
  export default {
    name: "ModifyChallenge",
    data() {
      return {
        challenge: null,
        line: null,
        message: ""
      };
    },
    computed: {
        lineOptions() {
        return [
          { value: null, text: "Please Select the Line" },
          {
            value: this.challenge.home.id,
            text: this.challenge.home.name
          },
          {
            value: this.challenge.away.id,
            text: this.challenge.away.name
          }
        ];
      }
    },
    created() {
    let self = this;
    api(
      `query { challenge(id:"${this.$route.params.id}") { 
        id
        spread
        totalpoints
        line {
          id
          name
        } 
        live
        lineFOdds
        lineAOdds
        spread
        spreadFOdds
        spreadAOdds
        totalpoints
        totalFOdds
        totalAOdds
        freePlay
        eventDate
        eventTime
        home {
          id
          name
          abbrev
          logo
          mascot
          stadium {
            name
            city
            state
            capacity
            latitude
            longitude
            } 
          }
          away {
            id
            name
            abbrev
            logo
            mascot
          }
          results {
            winner {
              id
              name
            }
            spread {
              id
              name
            }
            homePoints
            awayPoints
            totalpoints
          }
          bets {
            id
          }
        }
      }`
    ).then((data) => {
      self.challenge = data.challenge;
      if (data.challenge.line) {
        self.line = data.challenge.line.id;
      } else {
        self.line = null;
      }
    });
  },
  methods: {
    create() {
      let self = this;
      api(
        `mutation { makechallenge(cid:"${self.challenge.id}", date:"${self.challenge.eventDate}", time:"${self.challenge.eventTime}", line:${Number(self.line)}, moneyLineWinner:${self.challenge.lineFOdds}, moneyLineLoser:${self.challenge.lineAOdds}, sprd:${self.challenge.spread}, spreadOver:${self.challenge.spreadFOdds}, spreadUnder:${self.challenge.spreadAOdds}, pnts:${self.challenge.totalpoints}, pointsOver:${self.challenge.totalFOdds}, pointsUnder:${self.challenge.totalAOdds}){ code message } }`
      ).then(data => {
        this.$router.push({ name: "AdminChallenges" });
      });
    }
  }
  };
  </script>